<template>
  <div id="BCDOverview">
    <h1>{{ $t('system.netdoc') }}</h1>
    <BCDList :bcds="bcds" :bcd2subnets="bcd2subnets" :bcd2vlans="bcd2vlans" :ous="ous" :bcd2ous="bcd2ous"
      :default_sort_by="'name'" :bcd2vnis="bcd2vnis" :show_usage="false"
    ></BCDList>
  </div>
</template>

<script>
import RangeService from '@/api-services/dns_bcd.service'
import BCDList from '@/components/BCDList'
import apiutil from '@/util/apiutil'

export default {
  name: 'NetdocBCDOverview',
  components: {BCDList},
  data() {
    return {
      bcds: null,
      bcd2vlans: null,
      bcd2subnets: null,
      ous: null,
      bcd2ous: null,
      bcd2vnis: null
    }
  },
  async created() {
    const response = await RangeService.getOverview(this.$store.state)
    this.ous = response.data.unit_list
    this.bcds = response.data.bcd_list
    this.bcd2ous = response.data.bcd2ou_list
    this.bcd2subnets = apiutil.dict_of_lists_by_value_of_array(response.data.ip_subnet_list, 'bcd')
    this.bcd2vlans = apiutil.dict_of_lists_by_value_of_array(response.data.vlan_list, 'bcd')
    this.bcd2vnis = apiutil.dict_by_value_of_array(response.data.vxlan_list, 'bcd')
  }
}
</script>

<style scoped>

</style>
